import React from 'react';
import { Text } from 'react-native';
import { isEmpty } from 'lodash';
import { StyledText } from '@gf/cross-platform-lib/components';
import { useCurrencyFormatter } from '@gf/cross-platform-lib/utils';
import { isStateUsingEnhancedFee } from '@gf/cross-platform-lib/utils';
import { Carbonicons } from '@gf/cross-platform-lib/components';
import { styles } from './SeatSelectionCategories.styles';
import styled from '@gf/cross-platform-lib/styled-components';

interface SeatInfoProps {
  price: number;
  fee: number;
  seatLabel: string | undefined;
  selectedTicketType?: string;
  isAccessible: boolean;
  isEventDetails?: boolean;
  categoryLabel?: string;
  ticketName: string;
  financialSchoolState: string | undefined;
}

export const SeatInfo: React.FC<SeatInfoProps> = ({
  price,
  seatLabel,
  isAccessible,
  isEventDetails,
  categoryLabel,
  ticketName,
  fee,
  financialSchoolState
}) => {
  const formatCurrencyAndDropTrailingZeros = useCurrencyFormatter({}).formatAndDropTrailingZeros;
  const displayedTicketAmount = isStateUsingEnhancedFee(financialSchoolState || '') ? price + fee : price;

  return (
    <>
      <LabelText isEventDetails={isEventDetails}>{seatLabel}</LabelText>
      <StyledText typeToken={isEventDetails ? 'body01' : 'body02'} style={isEventDetails ? { width: 'auto' } : {}}>
        {formatCurrencyAndDropTrailingZeros(displayedTicketAmount)}
        {!isEmpty(ticketName) ? <> &bull; {ticketName}</> : <> &bull; {categoryLabel}</>}

        {isAccessible && (
          <>
            {' '}
            &bull;
            <>
              {' '}
              <Carbonicons name='accessibility' size={16} />
            </>
          </>
        )}
      </StyledText>
      {isStateUsingEnhancedFee(financialSchoolState || '') && fee ? (
        <StyledText typeToken='label01' style={{ lineHeight: 20, color: '#323232B2' }}>
          {formatCurrencyAndDropTrailingZeros(price)} price + {formatCurrencyAndDropTrailingZeros(fee)} fee
        </StyledText>
      ) : null}
    </>
  );
};

const LabelText = styled(Text)`
  ${styles.labelText}
`;
