import { css } from '@gf/cross-platform-lib/styled-components';

const baseCardStyle = css<{ isMobile: boolean; isEventDetails: boolean }>`
  width: ${({ isMobile, isEventDetails }) =>
    isMobile && isEventDetails ? '100%' : isMobile ? '320px' : isEventDetails ? '260px' : '380px'};
  background: rgb(255, 255, 255);
  border-radius: ${({ isMobile }) => (isMobile ? '0px' : '4px')};
  border: 2px solid rgb(211, 211, 211);
`;

const baseCardLeft = css<{ isMobile: boolean; isEventDetails: boolean }>`
  flex-direction: column;
  justify-content: center;
  padding: ${({ isEventDetails }) => (isEventDetails ? '16px 0px 16px 16px' : '16px')};
  flex-shrink: 1;
`;
export const styles = {
  selectedCategoriesWrapper: css<{ isMobile: boolean; isEventDetails: boolean }>`
    width: 100%;
    background: rgb(255, 255, 255);
    border-radius: ${({ isMobile }) => (isMobile ? '0px' : '0px')};
    align-items: ${({ isMobile }) => (isMobile ? 'stretch' : 'center')};
    margin-top: ${({ isEventDetails }) => (isEventDetails ? '0px' : '8px')};
    min-height: 174px;
  `,
  categoriesWrapper: css<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '100%' : '426px')};
    background: rgb(255, 255, 255);
    border-radius: ${({ isMobile }) => (isMobile ? '0px' : '0px')};
    align-items: center;
    margin-bottom: 8px;
    margin-top: 8px;
  `,
  cardWrapper: css<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '100%' : '48%')};
  `,
  categoriesCardWrapper: css<{ isEventDetails: boolean; isMobile: boolean }>`
    flex-direction: ${({ isEventDetails, isMobile }) => (isMobile ? 'column' : isEventDetails ? 'row' : 'column')};
    margin: ${({ isEventDetails }) => (isEventDetails ? 'auto' : '0')};
    width: 100%;
    max-width: ${({ isEventDetails }) => (isEventDetails ? '572px' : 'none')};
    justify-content: ${({ isEventDetails }) => (isEventDetails ? 'space-between' : 'flex-start')};
    align-items: ${({ isEventDetails }) => (isEventDetails ? 'flex-start' : 'center')};
    padding: ${({ isEventDetails }) => (isEventDetails ? '16px' : '0px')};
    flex-wrap: ${({ isEventDetails }) => (isEventDetails ? 'wrap' : 'nowrap')};
  `,
  categoriesCardAvailable: baseCardStyle,
  selectedCategoryCard: css<{ isMobile: boolean; isEventDetails: boolean; seatColor?: string }>`
    ${baseCardStyle}
    margin-top: 8px;
    border-left-color: ${({ seatColor }) => seatColor || 'rgb(211, 211, 211)'};
    border-left-width: ${({ seatColor }) => (seatColor ? '5px' : '2px')};
  `,
  maxWidth: css`
    width: 100%;
  `,
  categoriesCardUnavailable: css<{ isMobile: boolean }>`
    width: 380px;
    height: 56px;
    background: rgb(244, 244, 244);
    border-radius: 4px;
    flex-direction: row;
    width: ${({ isMobile }) => (isMobile ? '320px' : '380px')};
    justify-content: space-between;
    align-items: ${({ isMobile }) => (isMobile ? 'center' : 'center')};
    padding: 16px;
  `,

  cardLeft: css<{ isMobile: boolean; isEventDetails: boolean }>`
    ${baseCardLeft}
    width: ${({ isMobile }) => (isMobile ? '180px' : 'auto')};
  `,

  selectedCardLeft: css<{ isMobile: boolean; isEventDetails: boolean }>`
    ${baseCardLeft}
    width: ${({ isEventDetails, isMobile }) =>
      isMobile && isEventDetails ? 'auto' : isMobile ? '81%' : isEventDetails ? 'auto' : '81%'};
  `,
  cardRight: css<{ isMobile: boolean; isEventDetails: boolean }>`
    justify-content: center;
    padding: ${({ isEventDetails }) => (isEventDetails ? '0px 16px 0px 0px' : '16px')};
  `,
  cardTop: css`
    flex-direction: row;
    justify-content: space-between;
  `,
  cardBottom: css<{ isMobile: boolean }>`
    height: 35px;
    padding-left: 16px;
    padding-top: 8px;
  `,
  border: css<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '100%' : '378px')};
    height: 1px;
    border: 1px solid rgb(219, 219, 219);
  `,
  unavailableWrapper: css<{ isMobile: boolean }>`
    width: 96px;
    height: 24px;
    background-color: rgb(255, 215, 217);
    border-radius: 16px;
    justify-content: center;
  `,
  categoryTextWrapper: css<{ isMobile: boolean; isEventDetails: boolean }>`
    align-self: ${({ isMobile, isEventDetails }) => (isEventDetails ? 'flex-start' : isMobile ? 'auto' : 'flex-start')};
    padding-left: ${({ isEventDetails }) => (isEventDetails ? '16px' : '26px')};
    padding-bottom: ${({ isEventDetails }) => (isEventDetails ? '0px' : '8px')};
    padding-top: ${({ isEventDetails }) => (isEventDetails ? '0px' : '16px')};
    margin-right: ${({ isMobile, isEventDetails }) => (isEventDetails ? '0px' : isMobile ? '275px' : '0px')};
  `,
  labelText: css<{ isEventDetails: boolean }>`
    font-family: Roboto-Regular;
    font-size: ${({ isEventDetails }) => (isEventDetails ? '16px' : '18px')};
    line-height: 24px;
    width: ${({ isEventDetails }) => (isEventDetails ? 'auto' : '100%')};
    padding-right: 16px;
  `,
  trashWrapper: css`
    width: 32px;
    height: 32px;
    background: rgb(244, 244, 244);
    border-radius: 4px;
    align-items: center;
    justify-content: center;
  `,
  promoCodeWrapper: css<{ isMobile: boolean }>`
    flex-direction: row;
    margin-top: 16px;
    align-items: center;
    gap: 8px;
    ${({ isMobile }) =>
      isMobile &&
      `
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
    `}
  `
};
