import { ReservedSeat, GroupedCategories } from '@gf/cross-platform-lib/interfaces';

function groupSeatsByCategory(seats: ReservedSeat[]): GroupedCategories {
  const grouped = seats.reduce<GroupedCategories>((acc, seat) => {
    const category = seat.selectedSeat.category.label;
    if (typeof category === 'string') {
      if (!acc[category]) {
        acc[category] = {
          key: seat.selectedSeat.category.key,
          seats: []
        };
      }
      acc[category].seats.push(seat);
    }
    return acc;
  }, {});

  return grouped;
}

function sortSeatsWithinCategory(categorySeats: ReservedSeat[]): ReservedSeat[] {
  categorySeats.sort((seatA, seatB) => {
    const sectionA = seatA.selectedSeat.labels.section;
    const sectionB = seatB.selectedSeat.labels.section;
    const parentA = seatA.selectedSeat.labels.parent;
    const parentB = seatB.selectedSeat.labels.parent;
    const ownA = seatA.selectedSeat.labels.own;
    const ownB = seatB.selectedSeat.labels.own;

    if (!sectionA || !sectionB || !parentA || !ownA || !parentB || !ownB) {
      return 0;
    }

    const rowA = parseInt(parentA, 10);
    const rowB = parseInt(parentB, 10);
    const seatNumberA = parseInt(ownA, 10);
    const seatNumberB = parseInt(ownB, 10);

    if (sectionA !== sectionB) {
      return sectionA.localeCompare(sectionB);
    } else if (rowA !== rowB) {
      return rowA - rowB;
    } else {
      return seatNumberA - seatNumberB;
    }
  });

  return categorySeats;
}

function sortCategories(groupedCategories: GroupedCategories): Record<string, ReservedSeat[]> {
  return Object.entries(groupedCategories)
    .sort(([_a, categoryDataA], [_b, categoryDataB]) => {
      const keyA = categoryDataA.key;
      const keyB = categoryDataB.key;
      return keyA - keyB;
    })
    .reduce<Record<string, ReservedSeat[]>>((acc, [categoryLabel, categoryData]) => {
      acc[categoryLabel] = categoryData.seats;
      return acc;
    }, {});
}

export function groupReservedTicketsByCategory(seats: ReservedSeat[]): Record<string, ReservedSeat[]> {
  const groupedCategories = groupSeatsByCategory(seats);

  for (const categorySeats of Object.values(groupedCategories)) {
    sortSeatsWithinCategory(categorySeats.seats);
  }

  const sortedCategories = sortCategories(groupedCategories);

  return sortedCategories;
}
