import { useContext } from 'react';
import { ReservedSeatingContext } from '../providers/ReservedSeatingProvider';
import { ChartInstance, ReservedSeat, HoldToken } from '@gf/cross-platform-lib/interfaces';
import { safeFetch } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { config } from '@gf/cross-platform-lib/utils/config/config';
import { Method } from '@gf/cross-platform-lib/utils/ConfigTypes';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

const emptyReservedState = { selectedSeats: [], holdToken: null, chartInstance: null };

export interface EntireState {
  [eventId: string]: {
    chartInstance: ChartInstance | null;
    selectedSeats: ReservedSeat[];
    holdToken: HoldToken | null;
  };
}

interface ReservedSeatingHookReturn {
  reservedSeatsState: {
    chartInstance: ChartInstance | null;
    selectedSeats: ReservedSeat[];
    holdToken: HoldToken | null;
  };
  initChart: (chart: ChartInstance) => void;
  addSeat: (selectedSeat: ReservedSeat['selectedSeat'], price: number, ticketType: string | undefined) => void;
  removeSeat: (seatId: string, eventKey: string) => void;
  removeSeatLocal: (seatId: string) => void;
  clearSeats: () => void;
  addHoldToken: (holdToken: { token: string; expiresAt: string }) => void;
  resetState: () => void;
}

interface ReservedSeatingAllEventsHookReturn {
  reservedSeatsState: EntireState;
}

export function useReservedSeating(eventId: 'allEvents'): ReservedSeatingAllEventsHookReturn;
export function useReservedSeating(eventId?: string): ReservedSeatingHookReturn;

export function useReservedSeating(eventId?: string): ReservedSeatingHookReturn | ReservedSeatingAllEventsHookReturn {
  const context = useContext(ReservedSeatingContext);

  if (context === null) {
    throw new Error('useReservedSeating must be used within a ReservedSeatingProvider');
  }

  const [reservedSeatsState, dispatch] = context;

  const initChart = (chart: ChartInstance) => {
    if (!eventId) throw new Error('eventId is required for initChart');
    dispatch({ type: 'init_chart', eventId, payload: chart });
  };

  const addHoldToken = (holdToken: { token: string; expiresAt: string }) => {
    if (!eventId) throw new Error('eventId is required for addHoldToken');
    dispatch({ type: 'add_hold_token', eventId, payload: holdToken });
  };

  const addSeat = (selectedSeat: ReservedSeat['selectedSeat'], price: number, ticketType: string | undefined) => {
    if (!eventId) throw new Error('eventId is required for addSeat');
    dispatch({
      type: 'add_seat',
      eventId,
      payload: { selectedSeat, price, ticketType: ticketType, id: selectedSeat.id }
    });
  };

  const releaseSeatFromSeatsIo = async (eventKey: string, seatId: string) => {
    const getUrlForMethod = config.api.seatsIo.releaseSeats.getUrlForMethod;
    if (typeof getUrlForMethod !== 'function') {
      throw new Error('ReleaseSeats URL function is not defined');
    }
    const url = getUrlForMethod(Method.POST);
    if (!url) {
      throw new Error('ReleaseSeats URL is not defined');
    }
    await safeFetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ eventKey, seats: [seatId] })
    });
  };

  const removeSeat = async (seatId: string, eventKey: string) => {
    if (!eventId) throw new Error('eventId is required for removeSeat');

    const seatExists = reservedSeatsState[eventId]?.selectedSeats.some(seat => seat.selectedSeat.id === seatId);

    if (seatExists) {
      try {
        await releaseSeatFromSeatsIo(eventKey, seatId);
        dispatch({ type: 'remove_seat', eventId, payload: { id: seatId } });
      } catch (error: any) {
        recordError(error, {
          originatingFunction: 'removeSeat',
          customMessage: 'Failed to release seat from Seats.io',
          errorGroup: NEW_RELIC_ERROR_GROUPS.SeatsIo,
          data: { seatId, eventKey }
        });
      }
    }
  };

  const removeSeatLocal = (seatId: string) => {
    if (!eventId) throw new Error('eventId is required for removeSeat');
    dispatch({ type: 'remove_seat', eventId, payload: { id: seatId } });
  };

  const clearSeats = () => {
    if (!eventId) throw new Error('eventId is required for clearSeats');
    dispatch({ type: 'clear_seats', eventId });
  };

  const resetState = () => {
    dispatch({ type: 'reset_state' });
  };

  const sharedReturnObject = {
    initChart,
    addSeat,
    removeSeat,
    removeSeatLocal,
    clearSeats,
    addHoldToken,
    resetState
  };

  if (eventId === 'allEvents') {
    return { reservedSeatsState, ...sharedReturnObject } as ReservedSeatingAllEventsHookReturn;
  }

  if (eventId) {
    return {
      reservedSeatsState: reservedSeatsState[eventId] || emptyReservedState,
      ...sharedReturnObject
    } as ReservedSeatingHookReturn;
  }

  return { reservedSeatsState: emptyReservedState, ...sharedReturnObject };
}
